<template>
  <sdPageHeader title="Anti Konflik Kepentingan"> </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-form :model="formState" name="login" layout="vertical" @finish="handleSubmit" :rules="rules">
        <a-form-item name="search">
          <a-input v-model:value="formState.search" placeholder="Cari Berdasarkan NIK / NPWP" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-search" html-type="submit" type="primary" size="large" :loading="isLoading">
            Cari Data
          </sdButton>
        </a-form-item>
      </a-form>

      <!-- Hasil Pencarian -->
      <div v-if="data.message" class="search-result">
        <div v-if="!data.data" class="result-found">
          <div class="conflict-warning">
            <a-alert
              type="info"
              message="Data Tidak Ditemukan"
              description="Data dengan NIK / NPWP yang Anda cari tidak ditemukan."
              show-icon
            />
          </div>
        </div>
        <div v-else class="not-found">
          <div v-if="data.statusCode === 404" class="not-found">
            <!-- <a-alert
              type="success"
              message="Data Tidak Ditemukan"
              :description="`Data ${data.data.nama} tidak ditemukan.`"
              show-icon
            /> -->
            <div class="conflict-warning">
              <a-alert
                type="success"
                message="Tidak melanggar konflik kepentingan"
                :description="
                  data.found_in == 'pegawai'
                    ? `Data pegawai dengan ${data.column.toUpperCase()} ${
                        data.column === 'nik' ? data.data.nik : data.data.npwp
                      } atas nama ${
                        data.data.nama
                      } tidak melanggar konflik kepentingan, karena tidak ditemukan pada database vendor / mitra / pemasok / rekanan / penerima bantuan.`
                    : `Data mitra dengan ${data.column.toUpperCase()} ${
                        data.column === 'nik' ? data.data.nik : data.data.npwp
                      } atas nama ${
                        data.data.nama
                      } tidak melanggar konflik kepentingan, karena tidak ditemukan pada database pegawai.`
                "
                show-icon
              />
            </div>
          </div>
          <div v-else class="result-found">
            <!-- <a-alert
              type="error"
              message="Data Ditemukan"
              :description="`Data ${data.data.nama} ditemukan.`"
              show-icon
            /> -->
            <div class="conflict-warning">
              <a-alert
                type="warning"
                message="Mungkin melanggar konflik kepentingan"
                :description="
                  `Data pegawai dengan ${data.column.toUpperCase()} ${
                    data.column === 'nik' ? data.data.nik : data.data.npwp
                  } atas nama ${data.data.nama} mungkin melanggar konflik kepentingan, karena terdaftar ${
                    data.data.jabatan ? 'sebagai ' + data.data.jabatan : ''
                  } pada ${data.data.perusahaan}.`
                "
                show-icon
              >
                <template #icon>
                  <WarningOutlined />
                </template>
              </a-alert>
            </div>
          </div>
        </div>
      </div>
    </sdCards>
  </Main>
</template>

<script>
import { Main } from '../styled';
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { WarningOutlined } from '@ant-design/icons-vue';

const ViewPage = {
  name: 'AntiCoi',
  components: {
    Main,
    WarningOutlined,
  },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.crud.loading);
    const data = computed(() => state.crud.data); // Data dari Vuex
    const formState = reactive({
      search: '',
    });

    const handleSubmit = values => {
      formState.search = '';
      dispatch('axiosSearchNik', { search: values.search });
    };

    const rules = {
      search: [{ required: true, message: 'NIK / NPWP harus diisi', trigger: 'blur' }],
    };

    return {
      formState,
      handleSubmit,
      rules,
      isLoading,
      data,
    };
  },
};

export default ViewPage;
</script>

<style scoped>
.search-result {
  margin-top: 20px;
}

.not-found,
.result-found {
  margin-top: 20px;
}

.conflict-warning {
  margin-top: 10px;
}
</style>
