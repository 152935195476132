<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="dynamicRules"
          @finish="handleSubmit"
        >
          <a-form-item name="nama" label="Nama">
            <a-input v-model:value="formState.nama" placeholder="Masukkan Nama" />
          </a-form-item>
          <a-form-item name="nip" label="NIP">
            <a-input v-model:value="formState.nip" placeholder="Masukkan NIP" />
          </a-form-item>
          <a-form-item name="nik" label="NIK">
            <a-input
              v-model:value="formState.nik"
              placeholder="Masukkan NIK"
              type="tel"
              v-mask="'## ## ## ###### ####'"
            />
          </a-form-item>
          <a-form-item name="divisi_id" label="Divisi">
            <a-select
              placeholder="Pilih Divisi"
              v-model:value="formState.divisi_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.divisi" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="bidang_id" label="Bidang/Sub-Bidang/Divisi">
            <a-select
              placeholder="Pilih Bidang/Sub-Bidang/Divisi"
              v-model:value="formState.bidang_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.bidang" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="jabatan" label="Jabatan">
            <a-input v-model:value="formState.jabatan" placeholder="Masukkan Jabatan" />
          </a-form-item>
          <a-form-item name="npwp" label="NPWP">
            <template v-if="!isNpwpSameAsNik">
              <a-input
                v-model:value="formState.npwp"
                placeholder="Masukkan NPWP"
                type="tel"
                :disabled="isNpwpSameAsNik"
                v-mask="'##.###.###.#-###.###'"
              />
            </template>
            <template v-else>
              <a-input
                v-model:value="formState.npwp"
                placeholder="Masukkan NPWP"
                type="tel"
                :disabled="isNpwpSameAsNik"
              />
            </template>
          </a-form-item>
          <a-form-item :labelCol="{ span: 0 }" :wrapperCol="{ offset: 6 }">
            <a-checkbox v-model:checked="isNpwpSameAsNik" @change="handleNpwpCheckboxChange">
              Format baru (sama dengan NIK)
            </a-checkbox>
          </a-form-item>
          <a-form-item name="is_active" label="Status">
            <div style="display: flex; align-items: center; gap: 8px;">
              <a-switch v-model:checked="formState.is_active" />
              <span>{{ formState.is_active ? 'Aktif' : 'Non Aktif' }}</span>
            </div>
          </a-form-item>
          <a-form-item name="no_hp" label="No. HP / WA">
            <a-input v-model:value="formState.no_hp" placeholder="Masukkan No. HP / WA" type="number" />
          </a-form-item>
          <a-form-item name="no_telp" label="No. Telp Rumah">
            <a-input v-model:value="formState.no_telp" placeholder="Masukkan No. Telp Rumah" type="number" />
          </a-form-item>
          <a-form-item name="email" label="Email">
            <a-input type="email" v-model:value="formState.email" placeholder="Masukkan Email" />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>
                <router-link to="/master/pegawai">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, ref, watch, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { mask } from 'vue-the-mask';
import { Modal } from 'ant-design-vue';

const PegawaiForm = {
  name: 'PegawaiForm',
  components: { HorizontalFormStyleWrap, Main },
  directives: {
    mask,
  },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  computed: {
    dynamicRules() {
      const rules = { ...this.rules };
      if (this.mode === 'Ubah') {
        if (!this.formState.nip) {
          delete rules.nip;
        }
        if (!this.formState.nik) {
          delete rules.nik;
        }
        if (!this.formState.npwp) {
          delete rules.npwp;
        }
      }
      return rules;
    },
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const confirmMessage = computed(() => state.crud.confirmMessage);
    const confirmId = computed(() => state.crud.confirmId);
    const initUbah = ref(false);

    const formState = reactive({
      nama: '',
      nip: '',
      nik: '',
      organisasi: '',
      npwp: '',
      is_active: true,
      no_hp: '',
      no_telp: '',
      email: '',
      bidang_id: undefined,
      divisi_id: ref(undefined),
      jabatan: '',
    });

    watch(
      () => formState.nik,
      newNik => {
        if (isNpwpSameAsNik.value) {
          formState.npwp = newNik;
        }
      },
    );

    watch(
      () => formState.divisi_id,
      newDivisiId => {
        if (newDivisiId) {
          if (!initUbah.value) {
            formState.bidang_id = undefined; // Reset hanya jika initUbah masih false
          }
          dispatch('axiosCrudGetData', 'bidang-by-divisi' + '/' + newDivisiId).then(() => {
            options.bidang = crud.value;
          });
        }
      },
    );

    const isNpwpSameAsNik = ref(false);

    const options = reactive({
      bidang: [],
      divisi: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleNpwpCheckboxChange = checked => {
      if (checked.target.checked) {
        formState.npwp = formState.nik; // Set NPWP sama dengan NIK
      } else {
        formState.npwp = '';
      }
    };

    const handleSubmit = values => {
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'pegawai',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        if (values.bidang_id === undefined) {
          values.bidang_id = null;
        }

        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'pegawai',
          data: values,
        });
      }
    };

    const rules = {
      nama: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
      nip: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
      nik: [
        { required: true, message: 'Harap Diisi', trigger: 'blur' },
        { min: 20, max: 20, message: 'NIK harus 16 digit', trigger: 'blur' },
      ],
      organisasi: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
      email: [{ type: 'email', message: 'Format Email Tidak Valid', trigger: 'blur' }],
      npwp: [
        { required: true, message: 'Harap Diisi', trigger: 'blur' },
        { min: 20, max: 20, message: 'NPWP harus 15 digit', trigger: 'blur' },
      ],
      divisi_id: [{ required: true, message: 'Harap Dipilih', trigger: 'blur', type: 'number' }],
      jabatan: [{ required: true, message: 'Harap Diisi', trigger: 'blur' }],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-divisi-bidang').then(() => {
        options.divisi = crud.value;
      });

      if (props.mode == 'Ubah') {
        initUbah.value = true;

        dispatch('axiosSingleDataGet', {
          url: 'pegawai',
          id: params.id,
        }).then(() => {
          Object.keys(crud.value).forEach(key => {
            if (key === 'nik' || key === 'npwp' || key === 'nip') {
              return;
            }
            formState[key] = crud.value[key];
          });

          if (crud.value['nik'] === crud.value['npwp']) {
            isNpwpSameAsNik.value = true;
          }

          setTimeout(() => {
            initUbah.value = false;
          }, 1000);
        });
      }
    });

    watch(confirmMessage, newMessage => {
      if (newMessage) {
        Modal.confirm({
          title: 'Konfirmasi',
          content: newMessage + ' Apakah Anda ingin mengembalikan data yang sudah dihapus?',
          okText: 'Ya',
          cancelText: 'Batal',
          onOk() {
            dispatch('axiosCrudSubmitData', {
              url: 'rollback-pegawai',
              data: {
                id: confirmId.value,
              },
            });

            dispatch('resetConfirmMessage');
          },
          onCancel() {
            dispatch('resetConfirmMessage');
          },
        });
      }
    });

    return {
      formState,
      isNpwpSameAsNik,
      labelCol,
      wrapperCol,
      handleNpwpCheckboxChange,
      handleSubmit,
      isLoading,
      rules,
      filterSelect,
      options,
    };
  },
};

export default PegawaiForm;
</script>
