<template>
  <VendorForm mode="Tambah" module="Mitra"> </VendorForm>
</template>

<script>
import VendorForm from './form';

const VendorAdd = {
  name: 'VendorAdd',
  components: { VendorForm },
};

export default VendorAdd;
</script>
