<template>
  <VendorUploadForm mode="Import" module="Mitra"> </VendorUploadForm>
</template>

<script>
import VendorUploadForm from './uploadForm';

const VendorAdd = {
  name: 'VendorAdd',
  components: { VendorUploadForm },
};

export default VendorAdd;
</script>
